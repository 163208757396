





















































import { Component, Vue } from 'vue-property-decorator'
import OpenIssues from '@/main/issues/components/dashboard/OpenIssues.vue'
@Component({
  components: { OpenIssues }
})
export default class Dashboard extends Vue {
  changelog = [
    {
      version: '2021-06-11',
      items: [
        {
          title: '"New Sprint" reworked',
          desc: [
            'The way to create a new sprint has been reworked. You can now define a sprint goal while creating the sprint backlog'
          ]
        },
        { title: 'First steps', desc: ['"First steps" on the dashboard', 'Additional help texts'] },
        {
          title: 'Minor improvements & fixes',
          desc: [
            'Improvements to Issue view (e.g. Back Button)',
            'Fix links to access current sprint',
            'Validation shows missing or invalid fields immediately',
            '"History Mode" (no more "#" in url)',
            'Improve mobile view'
          ]
        }
      ]
    },
    {
      version: '2021-05-28',
      items: [{ title: 'Only show open issues not in an active or upcoming sprint' }]
    },
    {
      version: '2021-05-24',
      items: [
        {
          title: 'Add support for collaboration',
          desc: [
            'You can now invite other users to your product',
            'You can now assign issues Tasks and create as a team.',
            'You can everytime switch between all your products products'
          ]
        },
        {
          title: 'Minor changes',
          desc: [
            'New icons for the sidebar',
            'Increase usability of the product backlog',
            'Lighten up the color of the background slightly',
            'Add copy function to the title of and issue (for time tracking or commits)',
            'Improvements for the mobile view',
            'Fixed a few bugs'
          ]
        }
      ]
    },
    {
      version: '2021-05-02',
      items: [
        {
          title: 'Add support for markdown',
          desc: 'Use markdown to give your issues and tasks some structure. '
        }
      ]
    },
    {
      version: '2021-04-24',
      items: [
        {
          title: 'Add Sprints',
          desc:
            'Create a new Sprint from your backlog issue. Set a Name, a sprint goal and a duration and use the sprint ' +
            'board to create tasks for every issue'
        }
      ]
    },
    {
      version: '2021-01-24',
      items: [
        {
          title: 'Add Feedback for users',
          desc:
            'We added a simple feedback form for you. If you have any suggestion or discovered any bugs, please ' +
            "let us know. You'll find it in the header bar"
        }
      ]
    },
    {
      version: '2021-01-09',
      items: [
        {
          title: 'Add Changelog for issues',
          desc: 'Add an separate changelog for an issue. Every fieldchange will be logged (except transitions)'
        }
      ]
    },
    {
      version: '2020-11-21',
      items: [
        {
          title: 'Estimation with points',
          desc: 'Add an initial way to estimate an issue with points.'
        }
      ]
    },
    {
      version: '2020-11-19',
      items: [
        {
          title: 'Reworked Transition history',
          desc:
            'The transition history of an issue is now displayed as a timeline and renders the avatar image of the user'
        },
        {
          title: 'Changed primary color',
          desc: 'Changed the primary color to a little lighter color. Also matches the logo.'
        },
        {
          title: 'Minor fixes',
          desc: 'The issue dashboard card now consinders the current products and links to the product backlog'
        }
      ]
    },
    {
      version: '2020-11-18',
      items: [
        {
          title: 'Added product handling',
          desc:
            'Every new user must have at least one product. Every issue/sprint and so on will live inside this product.'
        },
        {
          title: 'Rewrite login cycle',
          desc:
            'Initial access to the app is redirected over the login page. If successfully logged in, you will ' +
            'redirected to your initial page or to the home page'
        },
        {
          title: 'Added changelog',
          desc: 'Initial Changelog for future changes'
        },
        {
          title: 'Add breadcrump to issue detail view'
        }
      ]
    }
  ]
}
